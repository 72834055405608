/* src/App.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

svg {
  background-color: white;
  border: 1px solid #ddd;
}

/* src/App.css */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
}

svg {
  background-color: white;
  border: 1px solid #ddd;
}

.tooltip {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #333;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
